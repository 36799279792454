<template>
  <div class="row justify-content-center">
    <customer-header />

    <vs-list>
      <vs-list-item class="k-nav-bar" title="Dashbord" subtitle="">
      </vs-list-item>
    </vs-list>

    <vs-card style="margin-top: 10px" v-if="enable_fee == true">
      <vs-list-item
        class="k-nav-bar"
        title="จำนวนเงินที่เก็บได้"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_date }}
                    </h2>
                    <p align="center">ยอดวันนี้</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                      style="background: rgba(var(--vs-success),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_month }}
                    </h2>
                    <p align="center">ยอดเดือนนี้</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-warning mb-4"
                      style="background: rgba(var(--vs-warning),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_lastMonth }}
                    </h2>
                    <p align="center">ยอดเดือนก่อน</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="จำนวนรถเข้าออก"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statCar_all }}
                    </h2>
                    <p align="center">ประจำวันทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div slot="no-body">
                  <div class="p-6 pb-0">
                    <div align="center">
                      <feather-icon
                        icon="TruckIcon"
                        class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                        style="background: rgba(var(--vs-success),0.15);"
                      >
                      </feather-icon>
                    </div>
                    <div class="truncate">
                      <h2 class="mb-1 font-bold" align="center">
                        {{ statCar_out }}
                      </h2>
                      <p align="center">ประจำวันกลับแล้ว</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-warning mb-4"
                      style="background: rgba(var(--vs-warning),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statCar_not_out }}
                    </h2>
                    <p align="center">ประจำวันยังไม่กลับ</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="เวลาเฉลี่ยที่อยู่ในโครงการประจำวัน"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_all }} นาที
                    </h2>
                    <p align="center">เวลาทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                      style="background: rgba(var(--vs-success),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_average }}
                    </h2>
                    <p align="center">เวลาเฉลี่ย</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_short }} นาที
                    </h2>
                    <p align="center">เวลาสั้นที่สุด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-danger mb-4"
                      style="background: rgba(var(--vs-danger),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_long }} นาที
                    </h2>
                    <p align="center">นานที่สุด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/8 xl:w-1/8 mb-base">
        <vx-card title="ประเภทรถที่เข้าออก ประจำวัน">
          <div slot="no-body">
            <vue-apex-charts
              type="pie"
              height="345"
              class="mt-10 mb-10"
              :options="statCarType"
              :series="statCarTypeList.series"
            />
            <!-- CHART DATA -->
            <ul class="mb-1">
              <li
                v-for="statCarTypeList in statCarTypeList.analyticsData"
                :key="statCarTypeList.customerType"
                class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
              >
                <span class="flex items-center">
                  <!-- <span
                    class="inline-block h-3 w-3 rounded-full mr-2"
                    :class="`bg-${statCarTypeList.color}`"
                  ></span> -->
                  <span class="font-semibold"
                   :style="{ color: statCarTypeList.color}"
                  >{{
                    statCarTypeList.carType
                  }}</span>
                </span>
                <span>{{ statCarTypeList.counts }}</span>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/8 xl:w-1/8 mb-base">
        <vx-card title="วัตถุประสงค์การเข้ามา ประจำวัน">
          <div slot="no-body">
            <vue-apex-charts
              type="pie"
              height="345"
              class="mt-10 mb-10"
              :options="staObjective"
              :series="statObjectiveList.series"
            />
            <!-- CHART DATA -->
            <ul class="mb-1">
              <li
                v-for="statObjectiveList in statObjectiveList.analyticsData"
                :key="statObjectiveList.customerType"
                class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
              >
                <span class="flex items-center">
                  <!-- <span
                    class="inline-block h-3 w-3 rounded-full mr-2"
                    :class="`bg-succes`"
                  ></span> -->
                  <span class="font-semibold"
                    :style="{ color: statObjectiveList.color}"
                  >{{
                    statObjectiveList.objective
                  }}</span>
                </span>
                <span>{{ statObjectiveList.counts }}</span>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน">
          <div slot="no-body" v-if="bool">
            <vue-apex-charts
              type="bar"
              height="266"
              :options="analyticsCar"
              :series="analyticsCarList"
            />
          </div>
        </vx-card>
      </div>
    </div>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="สรุป จำนวนรถเข้าออกประจำเดือนปัจจุบัน"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statMouth }}
                    </h2>
                    <p align="center">เดือนนี้ทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-danger mb-4"
                      style="background: rgba(var(--vs-danger),0.15);"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statLastMouth }}
                    </h2>
                    <p align="center">เดือนก่อน</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";

const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import vSelect from "vue-select";

// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from "vue-apexcharts";
// import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
// import analyticsData from './ui-elements/card/analyticsData.js'
// import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import _color from "@/assets/utils/color.js";
console.log("lang", lang);
import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);

Vue.component("customer-header", CustomerHeader);
Vue.component("vue-apex-charts", VueApexCharts);
Vue.component("v-select", vSelect);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  name: "ManageDashboard",
  components: {},
  data() {
    return {
      iconRight: {
        type: Boolean,
        default: false
      },
      hideChart: {
        type: Boolean,
        default: false
      },
      colorTo: {
        type: String
      },
      color: "primary",
      color1: "#A9A2F6",
      hideChart: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "line"
      },
      chartData: {
        // type: Array,
        // required: true
      },
      // Dashbord
      statAmount_date: 0,
      statAmount_month: 0,
      statAmount_lastMonth: 0,
      statCar_all: 0,
      statCar_out: 0,
      statCar_not_out: 0,
      statTime_all: 0,
      statTime_average: 0,
      statTime_short: 0,
      statTime_long: 0,
      statCarType: {
        // labels: ["taxi", "มอเเตอร์ไซด์"],
        labels: [],
        dataLabels: { enabled: false },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: { show: false }
        },
        stroke: { width: 5 },
        // colors: ["#7961F9", "#FF9F43", "#EA5455"],
        colors: [],
        fill: {
          type: "gradient"
        }
      },
      statCarTypeList:{},
      staObjective: {
        labels: [],
        dataLabels: { enabled: false },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: { show: false }
        },
        stroke: { width: 5 },
        colors: [],
        fill: {
          type: "gradient"
        }
      },
      statObjectiveList: {},
      analyticsCar: {
        grid: {
          borderColor: "#ebebeb",
          padding: { left: 0, right: 0 }
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        chart: { stacked: true, type: "bar", toolbar: { show: false } },
        colors: ["#7367F0", "#EA5455"],
        plotOptions: { bar: { columnWidth: "10%" } },
        xaxis: {
          labels: { style: { cssClass: "text-grey fill-current" } },
          axisTicks: { show: false },
          categories: [],
          // categories2: [
          //   "19/01/2021",
          //   "20/01/2021",
          //   "21/01/2021",
          //   "22/01/2021",
          //   "23/01/2021",
          //   "24/01/2021",
          //   "25/01/2021"
          // ],
          axisBorder: { show: false },
          convertedCatToNumeric: false
        },
        yaxis: {
          tickAmount: 5,
          labels: { style: { cssClass: "text-grey fill-current" } }
        },
        tooltip: { x: { show: false } }
      },
      analyticsCarList: [],
      statMouth: 0,
      statLastMouth: 0,
      bool:false,
      enable_fee: false,
    };
  },
  computed: {
    validName() {}
  },
  watch: {

  },
  computed: {

  },
  methods: {
    refor(n) { return n < 10 ? "0" + n : n },
    getDBQuery(_ducumentPath,doc) {
      let reference =  db.collection(_ducumentPath)
      if (doc) {
        reference = reference.doc(doc)
      }
      return reference;
    },
    dateStat(type){
      const dateNow = new Date();
      let d = null;
      if (type === "stat") {
        d = this.refor(dateNow.getDate()) + '' + this.refor(dateNow.getMonth() + 1) + '' + dateNow.getFullYear();
        // d = "18012021";
      } else  if (type === "stat_month") {
        d = this.refor(dateNow.getMonth() + 1) + '' + dateNow.getFullYear();
        // d = "012021";
      } else {
        // last month
        dateNow.setMonth(dateNow.getMonth() - 1);
        d = this.refor(dateNow.getMonth() + 1) + '' + dateNow.getFullYear();
      }
      return d
    },
    isEmpty(strIn) {
      if (strIn === "undefined") {
        return true;
      } else if(strIn === undefined) {
        return true;
      } else if(strIn == null) {
        return true;
      } else if(strIn == ""){
        return true;
      } else {
        return false;
      }
    },
    async init() {
      const userId = this.userInfo.user.email;
      const statDateId = this.dateStat("stat");
      const statMonthId = this.dateStat("stat_month");
      const lastStatMonthId = this.dateStat("last_stat_month");
      const statDoc = await this.getDBQuery("users/" + userId + "/stat/",statDateId).get().then();
      const statMonthDoc = await this.getDBQuery("users/" + userId + "/stat_month/",statMonthId).get().then();
      const statLastMonthDoc = await this.getDBQuery("users/" + userId + "/stat_month/",lastStatMonthId).get().then();
      const statCarType = await this.getDBQuery("users/" + userId + "/stat_car_type/").doc(statDateId).get();
      const statObjective = await this.getDBQuery("users/" + userId + "/stat_objective/").doc(statDateId).get().then();
      var objCarType = statCarType.data();

      var objCarTypeName = null;
      if (objCarType) {
        var objCarTypeName = Object.getOwnPropertyNames(objCarType);
      }
      var objObjective = statObjective.data();
      var objObjectiveName = null;
      if (objObjective) {
        objObjectiveName = Object.getOwnPropertyNames(objObjective);
      }
      // var obj = {a:1,b:2};
      // console.log(Object.getOwnPropertyNames(obj));

      // console.log("stat ",statDoc.id , statDoc.data());
      console.log("stat month ",statMonthDoc.id , statMonthDoc.data());
      // console.log("statDoc ",statDoc.data());
      // console.log("CarType name", objCarTypeName);
      // console.log("Objective name", objObjectiveName);
      if (statMonthDoc.data()) {
        // console.log("statMonthDoc total_fee ",statMonthDoc.data()['total_fee']);
        const statAmount_month = (statMonthDoc.data().total_fee?statMonthDoc.data().total_fee:0);
        this.statAmount_month = this.formatComma(statAmount_month.toString());
        const statMouth = (statMonthDoc.data().total_in?statMonthDoc.data().total_in:0);
        this.statMouth =  this.formatComma(statMouth.toString());
      }
      if (statLastMonthDoc.data()) {
        const statAmount_lastMonth = (statLastMonthDoc.data().total_fee?statLastMonthDoc.data().total_fee:0);
        this.statAmount_lastMonth =  this.formatComma(statAmount_lastMonth.toString());
        const statLastMouth = (statLastMonthDoc.data().total_in? statLastMonthDoc.data().total_in:0);
        this.statLastMouth = this.formatComma(statLastMouth.toString()) ;
      }

      if (statDoc.data()) {
        const statAmount_date = (typeof(statDoc.data().total_fee) !== "undefined" && statDoc.data().total_fee !== null?statDoc.data().total_fee:0);
        this.statAmount_date =  this.formatComma(statAmount_date.toString())
        const statCar_all = (statDoc.data().total_in?statDoc.data().total_in:0);
        this.statCar_all =  this.formatComma(statCar_all.toString());
        const statCar_out = (statDoc.data().total_out?statDoc.data().total_out:0);
        this.statCar_out =  this.formatComma(statCar_out.toString());
        const car_not_out = statDoc.data().total_in - statDoc.data().total_out;
        this.statCar_not_out =  (car_not_out < 0 ? 0 : car_not_out);
        const statTime_all = (statDoc.data().total_time?statDoc.data().total_time:0);
        this.statTime_all =  this.formatComma(statTime_all);
        const statTime_average = (statDoc.data().total_time / statDoc.data().total_in)
        this.statTime_average =  statTime_average.toFixed(2);
        this.statTime_average =  this.formatComma(this.statTime_average);
        const statTime_short = (statDoc.data().total_short_time?statDoc.data().total_short_time:0);
        this.statTime_short =   this.formatComma(statTime_short);
        this.statTime_long =  this.formatComma(statDoc.data().total_long_time);

        let objDataCar = [];
        let seriesCar = [];
        for(let i = 0;i < objCarTypeName.length;i++){
            this.statCarType.labels.push(objCarTypeName[i]);
            // let color = this.getColor();
            let color = this.getColorFix(i);
            this.statCarType.colors.push(color);
            objDataCar.push({
              carType: objCarTypeName[i],
              counts: objCarType[objCarTypeName[i]],
              color: color
            })
            seriesCar.push(objCarType[objCarTypeName[i]]);
        }

        this.statCarTypeList = {
          analyticsData: objDataCar,
          series: seriesCar
        };
        // console.log("this.statCarTypeList", this.statCarTypeList);
        // console.log("this.statCarType.labels", this.statCarType.labels);
        // console.log("this.statCarType.colors", this.statCarType.colors);
        let objDataObjective = [];
        let seriesObjective = [];
        for(let i = 0;i < objObjectiveName.length;i++){
            // labelsObjective.push(objObjectiveName[i]);
            this.staObjective.labels.push(objObjectiveName[i]);
            // let color = this.getColor();
            let color = this.getColorFix(i);
            this.staObjective.colors.push(color);
            objDataObjective.push({
              objective: objObjectiveName[i],
              counts: objObjective[objObjectiveName[i]],
              color: color
            })
            seriesObjective.push(objObjective[objObjectiveName[i]]);
        }
        this.statObjectiveList = {
          analyticsData: objDataObjective,
          series: seriesObjective
        };
        // console.log("this.statObjectiveList", this.statObjectiveList);
        // console.log("this.statCarType.labels", this.statCarType.labels);
        // console.log("this.statCarType.colors", this.statCarType.colors);
      }
    },
    getColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getColorFix(index) {
      // const color = ["#6A2CBB","#292b2c","#0275d8","#5cb85c","#5bc0de","#f0ad4e","#806517",'#d9534f','#B8860B','#F0E68C','#90EE90','#DB7093','#708090','#FFFAFA'];
      const color = ["#B5179E","#7209B7","#560BAD","#480CA8","#3A0CA3","#3F37C9","#4361EE",'#4895EF','#F68E5F',"#806517",'#B8860B','#F0E68C' ];
      return color[index]
    },
    getDays(d , Mention_today=true,id=false) {
      var DateArray = [];
      var days=d;
      for(var i=0;i<days;i++){
        if(!Mention_today && i==0){i=1;days+=1}
        var date = new Date();
        var last = new Date(date.getTime() - (i * 24 * 60 * 60 * 1000));
        var day =last.getDate();
        var month=last.getMonth()+1;
        var year=last.getFullYear();
        let fulld = '';
        if (id) {
          fulld = this.refor(Number(day))+''+this.refor(Number(month))+''+Number(year);
        } else {
          fulld = this.refor(Number(day))+'/'+this.refor(Number(month))+'/'+Number(year);
        }
        DateArray.push(fulld);
      }
      return DateArray;
    },
    formatComma(newValue) {
      return newValue.toString().replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  components: {
    Datepicker
  },
  async created() {
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");
    // console.log("[[this.userInfo]]", this.userInfo);
    const customerName = $cookies.get("customer");
    const customerRef = await this.getDBQuery("customer",customerName).get().then();
    console.log("enable_fee",customerRef.data().enable_fee);
    const userId = this.userInfo.user.email;
    const arrDay = this.getDays(7, true, true);
    const dataValue = [];
    this.enable_fee = customerRef.data().enable_fee;
    // if (customerRef.data().enable_fee === true) {
        for (let i = 0; i < 7;i++) {
          const statDoc = await this.getDBQuery("users/" + userId + "/stat/",arrDay[i]).get().then();
          if (statDoc.data()) {
            dataValue[i] =  statDoc.data().total_in;
          } else {
            dataValue[i] = 0;
          }
        }
        let arr = {
          name: "สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน",
          data: dataValue
        }
        this.analyticsCarList[0] = arr;
        const days = this.getDays(7, true, false);
        this.analyticsCar.xaxis.categories = days;

        this.init()
    // } else {
    //   let arr = {
    //       name: "สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน",
    //       data: [0,0,0,0,0,0,0]
    //   }
    //   this.analyticsCarList[0] = arr;
    //   const days = this.getDays(7, true, false);
    //   this.analyticsCar.xaxis.categories = days;
    // }

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch(error => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);
    this.bool = true;
    const n = 5000;
    console.log("COMMA TEST = ",this.formatComma(n.toString()))
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}

.vs-input {
  width: 100%;
  margin-top: 10px;
}

.page-item {
}
</style>
